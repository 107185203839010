const newBuyTicketObject = () => {
    return {
        selectedCityId: null,
        selectedCity: null,
        selectedCategoryId: null,
        selectedCategory: null,
        searchEventDate: null,
        selectedEventsSearchPeriodId: null,
        selectedEventsSearchPeriod: null,
        selectedEvent: null,
        selectedProposal: null,
        ticketsQuantity: null
    };
};

export default {
    namespaced: true,
    state() {
        const buyTicketObject = newBuyTicketObject();
        return {
            buyTicketObject
        }
    },
    getters: {},
    mutations: {
        resetBuyObject(state) {
            state.buyTicketObject = newBuyTicketObject()
        },
        setValue(state, { type, item }) {
            console.log(item);
            switch (type) {
                case 'selectedCity':
                    state.buyTicketObject.selectedCityId = item.id;
                    state.buyTicketObject.selectedCity = item.value;
                    break;
                case 'selectedCategory':
                    state.buyTicketObject.selectedCategoryId = item.id;
                    state.buyTicketObject.selectedCategory = item.value;
                    break;
                case 'selectedEventsSearchPeriod':
                    state.buyTicketObject.selectedEventsSearchPeriodId = item.id;
                    state.buyTicketObject.selectedEventsSearchPeriod = item.value;
                    if (state.buyTicketObject.selectedEventsSearchPeriodId != 5) {
                        state.buyTicketObject.searchEventDate = null;
                    }
                    break;
                default:
                    break;
            }
        },
        selectProposal(state, { event, proposal }) {
            state.buyTicketObject.selectedEvent = event
            state.buyTicketObject.selectedProposal = proposal
        },
        selectAlternateTicketsQuantity(state, quantity) {
            if (state.buyTicketObject.selectedProposal.ticketsQuantity != quantity) {
                state.buyTicketObject.ticketsQuantity = quantity
            }
        }
    },
    actions: {
        resetBuyObject({ commit }) {
            commit("resetBuyObject")
        },
        setValue({ commit }, { type, item }) {
            commit("setValue", { type, item })
        },
        selectProposal({ commit }, { event, proposal }) {
            commit("selectProposal", { event, proposal })
        },
        selectAlternateTicketsQuantity({ commit }, quantity) {
            commit("selectAlternateTicketsQuantity", quantity)
        }
    },
};